import React, { useState } from 'react';
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import { useGoogleLogin } from 'react-google-login'
import './index.css';

const clientId = "509577619353-gk8j5hdpkkqig9bcqajotu3n9qv5fdi9.apps.googleusercontent.com";
// const clientId = "68376004025-ql39v0kc3fgb8opdifjqh6fensrqga1n.apps.googleusercontent.com";
 const scope="https://www.googleapis.com/auth/analytics.readonly";

function Login() {

    // const { scope, loaded } = useGoogleLogin({
    //     scope
        
    //   })
    
   
    const [showloginButton, setShowloginButton] = useState(true);
    const [showlogoutButton, setShowlogoutButton] = useState(false);
    const onLoginSuccess = (res) => {
        console.log('Login Success:', res);
        setShowloginButton(false);
        setShowlogoutButton(true);
        // window.location.assign('/form_analytics')
       
    };

    const onLoginFailure = (res) => {
        console.log('Login Failed:', res);
    };

    const onSignoutSuccess = () => {
        alert("You have been logged out successfully");
        console.clear();
        setShowloginButton(true);
        setShowlogoutButton(false);
    };

    return (
        <div>
            <button>
                <a href="https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/analytics.readonly&
 access_type=offline&response_type=code&state=state_parameter_passthrough_value&
 redirect_uri=http://localhost:3000&client_id=509577619353-gk8j5hdpkkqig9bcqajotu3n9qv5fdi9.apps.googleusercontent.com">Connexion </a>
            </button>


            { showloginButton ?
                <GoogleLogin
                    clientId={clientId}
                    buttonText="Sign In"
                    onSuccess={onLoginSuccess}
                    scope={scope}
                    //  responseType="code"
                     responseType="token"
                     accessType="offline"
                    // responseType="code"
                    responseType="token"
                    approvalPrompt="force"   
                    prompt='consent'
                    onFailure={onLoginFailure}
                    cookiePolicy={'single_host_origin'}
                    isSignedIn={true}
                /> : null}

            { showlogoutButton ?
                <GoogleLogout
                    clientId={clientId}
                    buttonText="Sign Out"
                    onLogoutSuccess={onSignoutSuccess}
                >
                </GoogleLogout> : null
            }
        </div>
    );
}
export default Login;