import React, { Component } from "react";
import readXlsxFile from "read-excel-file";
// import { MDBDataTableV5 } from "mdbreact";
import { Button, Upload, Radio, Table, Checkbox, Switch, message } from "antd";
import { InboxOutlined, FileExcelOutlined } from "@ant-design/icons";
import { IoText } from "react-icons/io5";
import { TiSortNumerically } from "react-icons/ti";
import { FcOvertime } from "react-icons/fc";
// import CodeEditor from "@uiw/react-textarea-code-editor";
import callBack from "../../callback";
const _ = require("lodash");
const { Dragger } = Upload;
// const callBack = require("../../callback");

export default class Excel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      json: [],
      data: [],
      rows: [],
      col: [
        {
          title: "Colonne",
          width: 100,
          dataIndex: "name",
          key: "name",
          fixed: "left",
        },
        // {
        //   title: "Type de colonne",
        //   width: 100,
        //   render: (e) => {
        //     return (
        //       <Radio.Group
        //         options={[
        //           {value: "indicator", label: "Indicateur"},
        //           {value: "dimension", label: "Dimension"},
        //         ]}
        //         onChange={(value)=>this.setColumnType(value, e)}
        //         size="small"
        //         value={this.state.rows[e.index].columnType}
        //         defaultValue={e.columnType}
        //         optionType="button"
        //         buttonStyle="solid"
        //       />
        //     );
        //   },
        // },
        {
          title: "Indicateur",
          width: 100,
          render: (e) => {
            return (
              <Switch
                // options={[
                //   {value: "indicator", label: "Indicateur"},
                //   {value: "dimension", label: "Dimension"},
                // ]}
                onChange={(value) => this.setColumnType(value, e)}
                // size="small"
                checked={this.state.rows[e.index].columnType == "indicator"}
                // defaultValue={e.columnType}
                optionType="button"
                buttonStyle="solid"
              />
            );
          },
        },
        {
          title: "Type",
          width: 100,
          render: (e) => {
            return (
              <Radio.Group
                options={[
                  { value: 1, label: <TiSortNumerically /> },
                  { value: 2, label: <IoText /> },
                  { value: 3, label: <FcOvertime /> },
                ]}
                onChange={(value) => this.setDataType(value, e)}
                // size="small"
                value={this.state.rows[e.index].dataType}
                defaultValue={e.dataType}
                optionType="button"
                buttonStyle="solid"
              />
            );
          },
        },
        {
          title: "Ignorer",
          key: "ignored",
          // fixed: "right",
          width: 100,
          render: (e) => (
            <Checkbox
              onChange={(value) => this.setCheck(value, e)}
              defaultChecked={e.ignored}
              checked={this.state.rows[e.index].ignored}
            />
          ),
        },
      ],
    };
  }

  setCheck = (value, e) => {
    // console.log("value :>> ", value);
    this.state.rows[e.index].ignored = value.target.checked;
    this.setState({
      rows: [...this.state.rows],
    });
  };

  setDataType = (value, e) => {
    this.state.rows[e.index].dataType = value.target.value;
    this.setState({
      rows: [...this.state.rows],
    });
  };

  setColumnType = (value, e) => {
    // this.state.rows[e.index].columnType = value.target.value
    this.state.rows[e.index].columnType =
      this.state.rows[e.index].columnType == "indicator"
        ? "dimension"
        : "indicator";
    this.setState({
      rows: [...this.state.rows],
    });
  };

  componentDidMount() {}

  // selectFile = (e) => {
  //   readXlsxFile(e.target.files[0]).then((rows) => {
  //     console.log(rows);
  //     let data = [];
  //     if (rows.length > 0) {
  //       for (let i = 1; i < rows.length; i++) {
  //         let rowPushed = {};
  //         for (let j = 0; j < rows[0].length; j++) {
  //           const column = rows[0][j];
  //           rowPushed[column] = rows[i][j];
  //         }
  //         data.push(rowPushed);
  //       }
  //     }
  //     console.log(`data`, data);
  //     let columns = [];
  //     for (let j = 0; j < rows[0].length; j++) {
  //       const column = rows[0][j];
  //       let isNumber = false;
  //       let isString = false;
  //       let isObject = false;
  //       let isDate = false;
  //       let value = rows[1][j];

  //       console.log(`column`, typeof value);

  //       switch (typeof value) {
  //         case "number":
  //           isNumber = true;
  //           break;

  //         case "string":
  //           isString = true;
  //           break;

  //         case "object":
  //           isObject = true;
  //           try {
  //             value.getFullYear();
  //             isDate = true;
  //           } catch (e) {}
  //           break;
  //       }

  //       columns.push({
  //         index: j + 1,
  //         label: column,
  //         indicateur: isNumber,
  //         type: null,
  //         dimension: isString,
  //         date: isDate,
  //         temps: false,
  //         ignorer: isObject && !isDate ? true : false,
  //       });
  //     }

  //     let x = _.filter(columns,(o) => { return o.date; });
  //     console.log(`x`, x)
  //     if(x.length === 1){
  //       columns[x[0].index - 1].temps = true;
  //     }

  //     this.setState({ data, rows: columns });
  //   });
  // };

  selectFile = (e) => {
    this.setState({ loading: true });
    readXlsxFile(e).then((rows) => {
      // console.log(rows);
      let data = [...rows];
      data.splice(0, 1);
      console.log("data :>> ", data);
      let columns = [];
      for (let j = 0; j < rows[0].length; j++) {
        const column = rows[0][j];
        let isNumber = false;
        let isString = false;
        let isObject = false;
        let isDate = false;
        let value = rows[1][j];

        // console.log(`column`, typeof value);

        switch (typeof value) {
          case "number":
            isNumber = true;
            break;

          case "string":
            isString = true;
            break;

          case "object":
            isObject = true;
            try {
              value.getFullYear();
              isDate = true;
            } catch (e) {}
            break;
        }

        columns.push({
          index: j,
          name: column,
          columnType: isNumber ? "indicator" : "dimension",
          dataType: isNumber ? 1 : isDate ? 3 : isString ? 2 : null,
          ignored: isObject && !isDate ? true : false,
        });
      }

      this.setState({ data, rows: columns, loading: false });
    });
  };

  onSubmit = () => {
    // console.log("this.state.rows :>> ", this.state.rows);
    let rows = [...this.state.rows];

    let columns = _.filter(rows, (o) => {
      return !o.ignored;
    });
    let indicators = _.filter(columns, (o) => {
      return o.columnType === "indicator";
    });

    if (columns.length) {
      if (indicators.length) {
        // console.log("columns :>> ", columns);
        // console.log("indicators :>> ", indicators);
        let items = [];
        for (let index = 0; index < this.state.data.length; index++) {
          const row = this.state.data[index];
          let item = [];
          for (let j = 0; j < columns.length; j++) {
            const element = columns[j];
            item.push(row[element.index]);
          }
          items.push(item);
        }
        let col = [];
        for (let index = 0; index < columns.length; index++) {
          const element = columns[index];
          col.push({
            index: index,
            columnType: element.columnType,
            dataType: element.dataType,
            name: element.name,
          });
        }
        console.log("json :>> ", {
          columns: col,
          rows: items,
        });
        callBack(
          {
            columns: col,
            rows: items,
          },
          200
        );
      } else {
        message.error("Aucun indicateur");
      }
    } else {
      message.error("Aucune colonne");
    }
  };

  render() {
    return (
      <div className="container">
        {/* <h1>Connecteur excel</h1> */}
        {/* <input
          className={"btn btn-sm"}
          type="file"
          onChange={this.selectFile}
        /> */}
        <br />
        <br />
        <Dragger
          multiple={false}
          beforeUpload={(e) => {
            this.selectFile(e);
            return false;
          }}
          accept=".xlsx"
          action={null}
        >
          <p className="ant-upload-drag-icon">
            <FileExcelOutlined />
          </p>
          <p className="ant-upload-text">
            Cliquer ou glisser deposer votre fichier excel ici.
          </p>
          <p className="ant-upload-hint">
            Attention seul les fichiers xlsx sont pris en charge.
          </p>
        </Dragger>

        <Table
          columns={this.state.col}
          dataSource={this.state.rows}
          scroll={{ x: 700 }}
          loading={this.state.loading}
          pagination={false}
        />
        <br />
        <Button
          type="primary"
          onClick={this.onSubmit}
          // onClick={() => {
          //   console.log("this.state.rows :>> ", this.state.rows);
          // }}
        >
          Extraire les données
        </Button>

        <div className="card-body">
          <div className="table" style={{ width: "100%", overflowX: "auto" }}>
            {/* <MDBDataTableV5
              paging={false}
              searching={false}
              sortable={false}
              data={{ rows: this.state.rows, columns: this.state.columns }}
            /> */}
            {/* {this.state.rows.length > 0 ? (
              <div className="error-notice">
                <div className="oaerror info text-center">
                  <strong>Info</strong> Sélectionné l'axe de temps par rapport
                  auquelle les données seront representées (l'axes de temps doit
                  être une Date)
                </div>
              </div>
            ) : null} */}

            {/* -----table----- */}
            {/* {this.state.rows.length > 0 ? (
              <table className="table table-striped custab">
                <thead>
                  <tr>
                    <th className="text-center">N</th>
                    <th className="text-center">Données</th>
                    <th className="text-center">Indicateur</th>
                    <th className="text-center">Dimension</th>
                    <th className="text-center">Date</th>
                    <th className="text-center">Axe de temps</th>
                    <th className="text-center">Ignorer</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.rows.map((row, j) => {
                    return (
                      <tr key={j}>
                        <td className="text-center">{row.index}</td>
                        <td className="text-center">{row.label}</td>
                        <td className="text-center">
                          <input
                            type="checkbox"
                            checked={this.state.rows[j].indicateur}
                            onChange={() =>
                              (() => {
                                // console.log(`is`, this.state.rows[j].indicateur);
                                this.state.rows[j].indicateur =
                                  !this.state.rows[j].indicateur;
                                this.state.rows[j].temps = false;
                                if (this.state.rows[j].indicateur) {
                                  this.state.rows[j].dimension = false;
                                  this.state.rows[j].date = false;
                                }
                                this.setState({ rows: [...this.state.rows] });
                              })(j)
                            }
                          />
                        </td>
                        <td className="text-center">
                          <input
                            type="checkbox"
                            checked={this.state.rows[j].dimension}
                            onChange={() =>
                              (() => {
                                this.state.rows[j].dimension =
                                  !this.state.rows[j].dimension;
                                this.state.rows[j].temps = false;
                                if (this.state.rows[j].dimension) {
                                  this.state.rows[j].indicateur = false;
                                  this.state.rows[j].date = false;
                                }
                                this.setState({ rows: [...this.state.rows] });
                              })(j)
                            }
                          />
                        </td>
                        <td className="text-center">
                          <input
                            type="checkbox"
                            checked={this.state.rows[j].date}
                            onChange={() =>
                              (() => {
                                this.state.rows[j].date =
                                  !this.state.rows[j].date;

                                if (this.state.rows[j].date) {
                                  this.state.rows[j].indicateur = false;
                                  this.state.rows[j].dimension = false;
                                }
                                this.setState({ rows: [...this.state.rows] });
                              })(j)
                            }
                          />
                        </td>
                        <td className="text-center">
                          <input
                            type="checkbox"
                            checked={this.state.rows[j].temps}
                            onChange={() =>
                              (() => {
                                if (
                                  this.state.rows[j].date &&
                                  !this.state.rows[j].ignorer
                                ) {
                                  if (!this.state.rows[j].temps) {
                                    for (
                                      let index = 0;
                                      index < this.state.rows.length;
                                      index++
                                    ) {
                                      this.state.rows[index].temps = false;
                                    }
                                  }
                                  this.state.rows[j].temps =
                                    !this.state.rows[j].temps;

                                  this.setState({ rows: [...this.state.rows] });
                                }
                              })(j)
                            }
                          />
                        </td>
                        <td className="text-center">
                          <input
                            type="checkbox"
                            checked={this.state.rows[j].ignorer}
                            onChange={() =>
                              (() => {
                                this.state.rows[j].ignorer =
                                  !this.state.rows[j].ignorer;
                                this.state.rows[j].temps = false;
                                this.setState({ rows: [...this.state.rows] });
                              })(j)
                            }
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : null} */}
            {/* -----end table----- */}
            {/* <Button
              type="primary"
              onClick={() => {
                console.log("this.state.rows :>> ", this.state.rows);
              }}
            >
              Extraire les données
            </Button> */}
            {/* <button
              onClick={() => {
                let metrics = [];
                let dateColumn = "";
                for (let index = 0; index < this.state.rows.length; index++) {
                  const element = this.state.rows[index];
                  if (element.temps) {
                    dateColumn = element.label;
                    break;
                  }
                  // if (element.date) {
                  //   dateColumn = element.label;
                  //   break;
                  // }
                }
                for (let i = 0; i < this.state.rows.length; i++) {
                  const element = this.state.rows[i];
                  if (element.indicateur && !element.ignorer) {
                    let metric = {
                      name: element.label,
                      quantitative: true,
                      symbol: "",
                      metricType: "number",
                      dimension: [],
                      dataMetricEntry: [],
                    };

                    for (let j = 0; j < this.state.data.length; j++) {
                      const entry = this.state.data[j];
                      let month = entry[dateColumn].getMonth();
                      if (month !== 11) {
                        month++;
                      } else {
                        month = 1;
                      }
                      metric.dataMetricEntry.push({
                        // metricDate:
                        // entry[dateColumn].getFullYear() +
                        // "-" +
                        // month +
                        // "-" +
                        // entry[dateColumn].getDay(),
                        metricDate: `${entry[dateColumn].getFullYear()}-${
                          month > 9 ? month : "0" + month
                        }-${
                          entry[dateColumn].getUTCDate() > 9
                            ? entry[dateColumn].getUTCDate()
                            : "0" + entry[dateColumn].getUTCDate()
                        }`,
                        metricTime: `${
                          entry[dateColumn].getHours() > 9
                            ? entry[dateColumn].getHours()
                            : "0" + entry[dateColumn].getHours()
                        }:${
                          entry[dateColumn].getMinutes() > 9
                            ? entry[dateColumn].getMinutes()
                            : "0" + entry[dateColumn].getMinutes()
                        }:${
                          entry[dateColumn].getSeconds() > 9
                            ? entry[dateColumn].getSeconds()
                            : "0" + entry[dateColumn].getSeconds()
                        }`,
                        value: entry[element.label],
                      });
                    }

                    for (let k = 0; k < this.state.rows.length; k++) {
                      const e = this.state.rows[k];
                      if (e.dimension && !e.ignorer) {
                        let dimension = {
                          name: e.label,
                          dataDimMetricEntry: [],
                        };

                        let entries = [];

                        for (let l = 0; l < this.state.data.length; l++) {
                          const entry = this.state.data[l];

                          let month = entry[dateColumn].getMonth();
                          if (month !== 11) {
                            month++;
                          } else {
                            month = 1;
                          }

                          entries.push({
                            dimension: entry[e.label],
                            // metricDate:
                            //   entry[dateColumn].getFullYear() +
                            //   "-" +
                            //   month +
                            //   "-" +
                            //   entry[dateColumn].getDay(),
                            metricDate: `${entry[dateColumn].getFullYear()}-${
                              month > 9 ? month : "0" + month
                            }-${
                              entry[dateColumn].getUTCDate() > 9
                                ? entry[dateColumn].getUTCDate()
                                : "0" + entry[dateColumn].getUTCDate()
                            }`,
                            metricTime: `${
                              entry[dateColumn].getHours() > 9
                                ? entry[dateColumn].getHours()
                                : "0" + entry[dateColumn].getHours()
                            }:${
                              entry[dateColumn].getMinutes() > 9
                                ? entry[dateColumn].getMinutes()
                                : "0" + entry[dateColumn].getMinutes()
                            }:${
                              entry[dateColumn].getSeconds() > 9
                                ? entry[dateColumn].getSeconds()
                                : "0" + entry[dateColumn].getSeconds()
                            }`,
                            value: entry[element.label],
                          });
                          // entries[l][e.label] = entry[e.label];
                        }
                        // dimension.dataDimMetricEntry = _.groupBy(
                        //   entries,
                        //   e.label
                        // );
                        dimension.dataDimMetricEntry = entries;
                        console.log(`dimension`, dimension);
                        metric.dimension.push(dimension);
                      }
                    }

                    metrics.push(metric);
                  }
                }
                console.log(metrics);
                this.setState({ json: metrics });
                callBack(metrics, 200);
              }}
            >
              Extraire Données
            </button> */}

            {/* <CodeEditor
              value={JSON.stringify(this.state.json)}
              language="js"
              placeholder="Click to show result"
              // onChange={(evn) => setCode(evn.target.value)}
              padding={15}
              style={{
                fontSize: 12,
                backgroundColor: "#f5f5f5",
                fontFamily:
                  "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
              }}
            /> */}
          </div>
        </div>
      </div>
    );
  }
}
