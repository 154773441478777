const callBack = (data, status) => {
  window.parent.postMessage(
    JSON.stringify({ data, status, id: new Date().getTime() }),
    // "http://localhost:3000"
    // "https://viabber-dev.web.app"
    // "https://viabber-new-design.web.app"
    "https://testenv.viabber.com"
  );
  console.log("data send")
}

export default callBack;
