import React, { useState, useEffect } from "react";
import moment from "moment";
import { Select } from "antd";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import "./index.css";
import host from "../../../host";
import callBack from "../../../callback";
import { subtract } from "lodash";
import './index.css';
import logoSingin from '../../../images/btngoogle.png'

const _ = require("lodash");

function DataSource() {
  const [validated, setValidated] = useState(false);
  const [compte, setCompte] = useState("");
  const [propriete, setPropriete] = useState("");
  const [vue, setVue] = useState("");
  const [metric, setMetric] = useState("");
  const [dimension, setDimension] = useState("");
  const [ids, setIds] = useState("");
  const [dateDebut, setDateDebut] = useState("");
  const toDay = new Date();
  const dateFormat = moment(toDay).format('YYYY-MM-DD');
  const [dateFin, setDateFin] = useState(dateFormat);
  const [rafaicheValue, setRafaicheValue] = useState("auto");
  const [numFrequence, setNumFrequence] = useState(0);
  const [typeFrequence, setTypeFrequence] = useState("");

  const [allHeader, setAllHeader] = useState([]);
  const [allRows, setAllRows] = useState([]);

  //constante pour les appels APIs
  const [allCompte, setAllCompte] = useState([]);
  const [allProperties, setAllProperties] = useState([]);
  const [allview, setAllView] = useState([]);
  const [allMetric, setAllMetric] = useState([]);
  const [token, setToken] = useState("");
  const [refreshToken, setRefreshToken] = useState("");
  const [loginUrl, setLoginUrl] = useState('');
  // const [dateFin, setDateFin] = useState([]);
  const [code, setCode] = useState("");

  const [showloginButton, setShowloginButton] = useState(true);
  const [showlogoutButton, setShowlogoutButton] = useState(false);



  const name = "ga:country";
    // console.log("object: ", name.substr(3));

    //recuperer le nom de la Métric ou Dimension sans le ga: ex:ga:user => user
    const getName = (name) => {
      return name.substr(3)
    }
  
/*
    formater la  date en format  AAA-MM-DD
*/
    const buidDateFormat = (date) => {
      let dateformat;
        if(date){
           dateformat = date.substr(0, 4)+"-"+ date.substr(-4, 2)+"-"+ date.substr(-2, 2);
        }
        return dateformat;
    }


    const groupByElement =(params) => {
      const  tabUser = _.groupBy(params,"metricDate");
          const  tabNewElement = [];
          let index;
          for (const key in tabUser) {
            if (Object.hasOwnProperty.call(tabUser, key)) {
              let totalValue = 0;
              const element = tabUser[key];
              for (let i = 0; i < element.length; i++) {
                const item = element[i];
                 totalValue += parseInt(item.value);         
              }
              tabNewElement.push({
                // metricDate: key,
                metricDate: buidDateFormat(key),
                "metricTime": "12:00:00",
                "value": totalValue,
              })
            }
          }
          // console.log("tabNewElement: ", tabNewElement);
          return tabNewElement;
    }
  const groupByElementDimension = (params) => {
      const  tabUser = _.groupBy(params,"metricDate");
      const  tab = _.groupBy(params,"metricDate");
          const  tabNewElement = [];
          let index;
          for (const key in tabUser) {
            if (Object.hasOwnProperty.call(tabUser, key)) {
              let totalValue = 0;
              let item;
              const element = tabUser[key];
              for (let i = 0; i < element.length; i++) {
                 item = element[i];
                // console.log("item: ", item);
                 totalValue += parseInt(item.value);         
              }
              tabNewElement.push({
                //  lombre: key,
                dimension: item.dimension,
                metricDate: buidDateFormat(key),
                "metricTime": "12:00:00",
                "value": totalValue,
              })
            }
          }
          // console.log("tabNewDimension: ", tabNewElement);
          return tabNewElement;
    }
        

  // console.log("_lodash",_.groupBy(users,"metricDate"))



  const { Option } = Select;

  const tokenUser = token;

  const clientId = "509577619353-gk8j5hdpkkqig9bcqajotu3n9qv5fdi9.apps.googleusercontent.com";
  const scope = "https://www.googleapis.com/auth/analytics.readonly";

  const onLoginSuccess = (res) => {
    console.log("Login Success:", res);
    setCode(res.code)
    allTokens(res.code);
    // setToken(res.accessToken);
    // setToken(res.code);
    // getAllCompte(res.accessToken);
    // getAllCompte(res.code);
    // console.log("propriete: ",propriete)
    // getAllPropreties(compte, res.accessToken);
    // getAllViews(propriete, res.accessToken, compte);
    setShowloginButton(false);
    setShowlogoutButton(true);
    // window.location.assign('/form_analytics')
  };

  const onLoginFailure = (res) => {
    console.log("Login Failed:", res);
  };

  const onSignoutSuccess = () => {
    // alert("You have been logged out successfully");
    console.clear();
    setShowloginButton(true);
    setShowlogoutButton(false);
  };
  
  // get access token
  const allTokens = (code) => {
      // NEW CALL token
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "code": code
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

    fetch(host+"/code", requestOptions)
      .then(response => {
          if(response.status === 200){
            return response.json();
          }
      })
      .then(result => {
        console.log("alllTokennn: ",result)
        setToken(result.data.access_token);
        setRefreshToken(result.data.refresh_token);
        getAllCompte(result.data.access_token);
      })
      .catch(error => console.log('error', error));
  }

  //get allCompte about user login
  const getAllCompte = (token) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer {{token}}");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${host}/account/?token=${token}`, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
      })
      .then((result) => {
        setAllCompte(result.items);
      })
      .catch((error) => {
        // console.log('errorLomb', error)
        console.log("");
      });
  };

  //get all properties  about the compte
  const getAllPropreties = (accountId, token) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer {{token}}");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${host}/properties/?token=${token}&accountId=${accountId}`,
      requestOptions
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        if (response.status === 400) {
          setAllProperties([]);
        }
      })
      .then((result) => {
        setAllProperties(result.data.items);
      })
      .catch((error) => {
        // console.log('error', error)
        console.log("");
      });
  };

  //get all Views
  const getAllViews = (webID, token, accountId) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer {{token}}");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${host}/profiles/?token=${token}&accountId=${accountId}&webID=${webID}`,
      requestOptions
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        if (response.status === 400) {
          setAllView([]);
          setIds("");
          setAllMetric([]);
        }
      })
      .then((result) => {
        console.log("IdsLomb:", result.data.items);

        setAllView(result.data.items);
        setIds(vue ? `ga:${vue}` : "");
        getAllMetrics();
      })
      .catch((error) => {
        // console.log('IdsLombError', error)
        console.log("");
      });
  };

  //get all Metrics
  const getAllMetrics = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(host+"/metrics/", requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
      })
      .then((result) => {
        // if(result.items.attributes.type == "DIMENSION"){
        console.log("Suis une Dimension");

        // }

        if (vue !== "") {
          // if(result.attributes.type === "METRIC"){
          setAllMetric(result.items);
          // }
        }
      })
      .catch((error) => {
        // console.log('error', error)
        console.log("");
      });
  };

  //get the Rapport 1
  const getRapport = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    var raw = JSON.stringify({
      "token": `${token}`,
      "refreshToken": `${refreshToken}`,
      "enddate": `${dateFin}`,
      "startdate": `${dateDebut}`,
      "metrics": `${metric}`,
      "dimension": `${dimension}`,
      "ids": `${ids}`,
      "vue": `${vue}`,
      "propriete": `${propriete}`,
      "compte": `${compte}`,
      "numFrequence": `${numFrequence}`,
      "typeFrequence": `${typeFrequence}`,
      "rafaicheValue": `${rafaicheValue}`
    });
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    fetch(host+"/analyze/", requestOptions)
      .then(response =>{
        if(response.status === 200){
          return response.json();
        }
        // response.text()
      })
      .then(result =>{
        console.log("RESPONSE_LOGIN: ", result);
        let indexOfDate;
        let columnHeaders = [];
        let rows = [];
        let tab = [];
        let url = result.url;
        for (let i = 0; i < result.data.columnHeaders.length; i++) {
          const element = result.data.columnHeaders[i];
          if (element.name === "ga:date") {
            console.log("indexOfDate:",i);
            indexOfDate = i;
          }
            if(result.data.columnHeaders[i].columnType == "DIMENSION"){
              if(result.data.columnHeaders[i].dataType == "STRING"){
                if(result.data.columnHeaders[i].name == "ga:date"){
                  columnHeaders.push({
                    columnType: "dimension",
                    dataType: 3,
                    name: getName(element.name) ,
                    index: i
                  })
                }else{
                  columnHeaders.push({
                    columnType: "dimension",
                    dataType: 2,
                    name: getName(element.name),
                    index: i
                  })
                }              
              }  
              if(result.data.columnHeaders[i].dataType == "INTEGER"){
                columnHeaders.push({
                  columnType: "dimension",
                  dataType: 1,
                  name: getName(element.name),
                  index: i
                })
              }                      
            }
            if(result.data.columnHeaders[i].columnType == "METRIC"){
              if(result.data.columnHeaders[i].dataType == "STRING"){
                if(result.data.columnHeaders[i].name == "ga:date"){
                  columnHeaders.push({
                    columnType: "indicator",
                    dataType: 3,
                    name: getName(element.name),
                    index: i
                  })
                }else{
                  columnHeaders.push({
                    columnType: "indicator",
                    dataType: 2,
                    name: getName(element.name),
                    index: i
                  })
                }              
              }  
              if(result.data.columnHeaders[i].dataType == "INTEGER"){
                columnHeaders.push({
                  columnType: "indicator",
                  dataType: 1,
                  name: getName(element.name),
                  index: i
                })
              } 
                                   
            }
                  
        }

        for (let i = 0; i < columnHeaders.length; i++) {
           const elementHeader = columnHeaders[i];
           if (elementHeader.dataType == 3) {
            for (let index = 0; index < result.data.rows.length; index++) {
              const elementRow = result.data.rows[index];
              elementRow[elementHeader.index] = buidDateFormat(elementRow[elementHeader.index]);
                 rows.push(elementRow);
            }
          }
         
        }
       
        tab.push({
          columns: columnHeaders,
          rows: rows,
          url: url
        })
        callBack({
          columns: columnHeaders,
          rows: rows,
          url: url
        }, 200);
        console.log("callBackIdem: ", tab);
        console.log("columnHeaders: ", columnHeaders);
        console.log("ROWs: ", rows);
        console.log("ALL: ", tab);
      })
      .catch(error => console.log('error', error));
    
   
    
  };

  //get the Rapport 2
  // const getRapport_2 = (
  //   token,
  //   startdate,
  //   enddate,
  //   metrics,
  //   dimensions,
  //   ids
  // ) => {
  //   var myHeaders = new Headers();
  //   myHeaders.append("Authorization", `Bearer ${token}`);
  //   // console.log("DIMESDFG:", dimensions);
  //   console.log("numFrequence", numFrequence);
  //   console.log("typeFrequence", typeFrequence);
  //   console.log("rafaicheValue", rafaicheValue);

  //   var requestOptions = {
  //     method: "GET",
  //     headers: myHeaders,
  //     redirect: "follow",
  //   };

  //   fetch(
  //     `https://www.googleapis.com/analytics/v3/data/ga?ids=${ids}&dimensions=${dimensions}&metrics=${metrics}&start-date=${startdate}&end-date=${enddate}`,
  //     requestOptions
  //   )
  //     .then((response) => {
  //       console.log("RESPONSE:", response);
  //       if(response.status === 200){
  //         return response.json();
  //       }
  //     })
  //     .then((result) => {
  //       console.log("ANALYSE: ", result);
  //       // setAllHeader(result.columnHeaders);
  //       // setAllRows(result.rows);
  //       let indexOfDate;
  //       let indexOfCity;
  //       for (let index = 0; index < result.columnHeaders.length; index++) {
  //         const element = result.columnHeaders[index];
  //         if (element.name === "ga:date") {
  //           console.log("indexOfDate:",index);
  //           indexOfDate = index;
  //         }
  //         if (element.name === "ga:city") {
  //           console.log("indexOfCity:",index);
  //           indexOfCity = index;
  //         }
  //       }
  //       let tab = [];
      
  //       for (let index = 0; index < result.columnHeaders.length; index++) {
  //         const element = result.columnHeaders[index];
  //         if (element.columnType === "METRIC") {
  //           let metricEntries = [];
  //           let dimension = [];
  //           for (let i = 0; i < result.rows.length; i++) {
  //             const element = result.rows[i];
  //             metricEntries.push({
  //               metricDate: element[indexOfDate],
  //               value: element[index],
  //               metricTime: "10:00:00",
  //             });
  //           }
  //           for (let i = 0; i < result.columnHeaders.length; i++) {
  //             const elementDimension = result.columnHeaders[i];
  //             if(elementDimension.columnType === "DIMENSION" && elementDimension.name !== "ga:date"){
  //                   let dimMetricEntry = [];
                    
  //                   for (let j = 0; j < result.rows.length; j++) {
  //                     const element = result.rows[j];

  //                     // console.log("dimMetri: ", result.rows);

  //                     dimMetricEntry.push({
  //                       dimension: element[i],
  //                       metricDate: element[indexOfDate],
  //                       value: element[index],
  //                       metricTime: "10:00:00",
  //                     });
  //                   }
  //                   dimension.push({
  //                     "name": getName(elementDimension.name),
  //                     // "dataDimMetricEntry": dimMetricEntry,
  //                      "dataDimMetricEntry": groupByElementDimension(dimMetricEntry),
  //                     "code":"hidden",
  //                     "businessentity":  ""
  //                   })
  //                   //  console.log("dimMetricEntry", dimMetricEntry);
  //             }
              
              
  //           }
  //           tab.push({
  //             name: getName(element.name),
  //             quantitative: true,
  //             symbol: "",
  //             metricType: element.dataType,
  //             dimension: dimension,
  //             dataMetricEntry: groupByElement(metricEntries),
  //           });
  //         }
  //       }
  //       callBack(tab, 200);
  //       console.log("callBackIdem: ", tab);
        



  //     })
  //     .catch((error) => console.log("errorll", error));
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    const metrics = metric.join(",");

    if(dimension == "") {
        // setDimension("ga:date")
        console.log("dimensionInferieur ", dimension)
        // getRapport_2(tokenUser, dateDebut, dateFin, metrics, "ga:date", ids);
        getRapport();
    }else{
      
      if (!_.find(dimension, function (o) {
        return o === "ga:date";
      })
    ) {
      dimension.push("ga:date");
      console.log("Dimension_Date Existe dèjà.", dimension);
    }
    const dimensions = dimension.join(",");

    // getRapport_2(tokenUser, dateDebut, dateFin, metrics, dimensions, ids);
    getRapport();
    }
  };

  //selected the metric
  const handleChangeMetric = (value) => {
    setMetric(value);
  };
  //selected the Dimension
  const handleChangeDimension = (value) => {
    setDimension(value);
  };

  

  useEffect(() => {
    if(token && compte){
      getAllCompte(tokenUser);
    // console.log("propriete: ",propriete)
     console.log("token && compte: existe")
    getAllPropreties(compte, tokenUser);
    if(propriete){
      getAllViews(propriete, tokenUser, compte);
    }
    }
  }, [compte, propriete, vue]);


  // Vérification des entrées du formulaire
  const buttonSubmit = compte === "" || ids === "" || propriete === "" || vue === "" || metric === "" || dateDebut === "" || dimension === "" ||
    dateFin === ""  ? <button disabled onClick={handleSubmit} className="btn btn-secondary" type="submit"> Ajouter </button> :
    <button  onClick={handleSubmit} className="btn btn-primary" type="submit"> Ajouter </button>

  return (
    <form onSubmit={handleSubmit} className="container" action="">
      {/* Button de login to Analytics compte */}
      <div className="ga-login mr-auto">
        {showloginButton ? (
          <GoogleLogin
          clientId={clientId}
          buttonText="Connexion"
          onSuccess={onLoginSuccess}
          scope={scope}
          responseType="code"
          accessType="online"
          // uxMode="redirect"
          // redirectUri="https://api-google-analytic.herokuapp.com"
          // responseType="token"
          // approvalPrompt="force"
          prompt="consent"
          onFailure={onLoginFailure}
          cookiePolicy={"single_host_origin"}
          isSignedIn={true}
        />
          
               
          
        ) : null}

        {showlogoutButton ? (
          <GoogleLogout
            clientId={clientId}
            buttonText="Déconnexion"
            onLogoutSuccess={onSignoutSuccess}
          ></GoogleLogout>
        ) : null}
      </div>
      <br />
      <br />
      <br />
      <label> Compte <strong className="etoile"><em>*</em></strong></label>
      <select
        value={compte}
        onChange={(e) => setCompte(e.target.value)}
        class="form-select"
        aria-label="Default select example"
      >
        <option>Sélectionner un compte</option>
        {allCompte.map((compte) => {
          return (
            <option key={compte.id} value={compte.id}>
              {compte.name}{" "}
            </option>
            // console.log("compte:", compte.name)
          );
        })}
      </select>
      <br />
      <br />
      <label> Propriété <strong className="etoile"><em>*</em></strong></label>
      <select
        value={propriete}
        onChange={(e) => setPropriete(e.target.value)}
        class="form-select"
        aria-label="Default select example"
      >
        <option>Sélectionner une propriété</option>
        {allProperties.map((proprety) => {
          return (
            <option key={proprety.id} value={proprety.id}>
              {proprety.name}{" "}
            </option>
            // console.log("compte:", compte.name)
          );
        })}
      </select>
      <br />
      <label> Vue <strong className="etoile"><em>*</em></strong></label>
      <select
        value={vue}
        onChange={(e) => setVue(e.target.value)}
        class="form-select"
        aria-label="Default select example"
      >
        <option>Sélectionner une vue</option>

        {
          // console.log("Views: ", allview)
          allview.map((view) => {
            return (
              <option key={view.id} value={view.id}>
                {view.name}{" "}
              </option>
            );
          })
        }
      </select>
      <br />
      <label> Ids<strong className="etoile"><em>*</em></strong></label>
      <input
        disabled
        type="text"
        value={ids}
        onChange={(e) => setIds(e.target.value)}
        className="form-control"
      />{" "}
      <br />
      <label htmlFor="">Metirc <strong className="etoile"><em>*</em></strong></label>
      <Select
        // class="form-select"
        mode="multiple"
        allowClear
        style={{ width: "100%" }}
        placeholder="selectionné vos métriques"
        onChange={handleChangeMetric}
      >
        {allMetric.map((metric) => {
          return metric.attributes.type == "METRIC" ? (
            <Option key={metric.id} value={metric.id}>
              <strong>{metric.attributes.uiName}</strong> <br />
              {metric.id}
            </Option>
          ) : (
            ""
          );
        })}
      </Select>{" "}
      <br />
      <br />
      <label htmlFor="">Dimension<strong className="etoile"><em>*</em></strong></label>
      <Select
        // class="form-select"
        mode="multiple"
        allowClear
        style={{ width: "100%" }}
        placeholder="selectionné vos Dimensions"
        onChange={handleChangeDimension}
      >
        {allMetric.map((metric) => {
          return metric.attributes.type == "DIMENSION" ? (
            <Option key={metric.id} value={metric.id}>
              <strong>{metric.attributes.uiName}</strong> <br />
              {metric.id}
            </Option>
          ) : (
            ""
          );
        })}
      </Select>{" "}
      <br />
      <br />
      <label htmlFor="dateDebut">Date de debut <strong className="etoile"><em>*</em></strong></label>
      <input
        value={dateDebut} 
        onChange={(e) => setDateDebut(e.target.value)}
        className="form-control"
        type="date"
        name="datedebut"
        id="dateDebut"
      />
      <br />
      <br />
      <label htmlFor="datefin">Date de fin <strong className="etoile"><em>*</em></strong></label>
      <input
        value={dateFin}
        onChange={(e) => setDateFin(e.target.value)}
        className="form-control"
        type="date"
        name="datefin"
        id="datefin"
      />
      <br />
      <label> Type de rafraichissement  <strong className="etoile"><em>*</em></strong></label>
      <select
        value={rafaicheValue}
        onChange={(e) => setRafaicheValue(e.target.value)}
        class="form-select"
        aria-label="Default select example"
      >
          {/* <option>Sélectionner un type</option> */}
            <option selected="selected"  value="auto">Automatique</option>
            <option  value="manuel">Manuel</option>
      </select>
      <br />
      <br />
      <div className="row">
      <label> Fréquence  <strong className="etoile"><em>*</em></strong></label>
        <div className="col-md-3">
            <input  value={numFrequence}  onChange={(e) => setNumFrequence(e.target.value)} min="0" max="100" class="form-control" type="number" />
        </div> <br /><br /><br />
        <div className="col-md-2">
          <select  value={typeFrequence} onChange={(e) => setTypeFrequence(e.target.value)} class="form-select" name="" id="">
          <option>Sélectionner un type</option>
            <option value="heure">Heure</option>
            <option value="semaine">Semaine</option>
            <option value="mois">Mois</option>
          </select>
        </div>
      </div><br /><br />

      
     <p> <strong className="etoile"><em>(*)</em></strong>  Champ obligatoire</p><br />
        {buttonSubmit}
    </form>
  );
}

export default DataSource;
