import logo from "./logo.svg";
import "./App.css";
import { useState, useEffect } from "react";

function App() {
  const [source, setSource] = useState(null);
  const [texte, setTexte] = useState("");

  useEffect(() => {
    window.parent.postMessage(
      JSON.stringify({ info: "init" }),
      "https://viabber-dev.web.app"
    );
    window.addEventListener(
      "message",
      (e) => {
        if (e.origin == "https://viabber-dev.web.app") {
          setSource(e);
          console.log(e.source);
          const data = JSON.parse(e.data);
          switch (data.info) {
            case "init":
              alert("init");
              // e.source.postMessage(JSON.stringify({info:"init"}), e.origin);
              break;

            // case "settitle":

            //   break;

            default:
              break;
          }
        }
      },
      false
    );
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
        <input
          value={texte}
          type={"text"}
          onChange={(e) => {
            setTexte(e.target.value);
          }}
        />
        <button
          onClick={() => {
            if (source !== null) {
              source.source.postMessage(
                JSON.stringify({ info: "settitle", data: texte }),
                source.origin
              );
            }
          }}
        >
          Call parent
        </button>
      </header>
    </div>
  );
}

export default App;
