import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Excel from './connectors/Excel';
import App from './App';
import Login from "./connectors/GaAnalytics/Login";
import DataSource from "./connectors/GaAnalytics/DataSource";

const AppContainer = function (props) {
  if (props) {
    return (
      <Router> 
        <Switch>
          <Route path="/excel" exact component={Excel} />
          <Route path="/" exact component={App} />
          <Route path="/login" exact component={ Login} />
          <Route path="/ga_form" exact component={ DataSource} />
        </Switch>
      </Router> 
    );
  }
  return null;
};

export default AppContainer;
